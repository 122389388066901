/* styles.css */
.hover-target {
  position: relative;
}

.mouse-target {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  padding: 10px;
  font-size: 1.2rem;
}

.mouse_stalker {
  background-color: whitesmoke;
  border-radius: 50%;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  mix-blend-mode: difference;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  width: 80px;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 599px) {
  .mouse_stalker {
    display: none;
  }
}
